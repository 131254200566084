@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.registration,
.yearOfManufacture,
.atm,
.tare {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.compliancePlateAdvice {
  composes: marketplaceSmallFontStyles from global;
  background-color: var(--matterColorNegative);
  padding: 8px 12px;
  border-radius: 1em;
  margin-bottom: 25px;
  margin-top: 0;
  line-height: normal;
}
