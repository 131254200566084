.root {
  background-color: var(--marketplaceColorDark);
  text-align: center;
  padding: 10px 20px 9px;
}

.text {
  margin: 0;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  margin-bottom: 14px;
  line-height: 18px;
}

.finishListingDraftButtonWrapper {
  margin-bottom: 6px;
}

.finishListingDraftLink {
  background: var(--marketplaceColor);
  margin: 0 16px;
  padding: 8px 16px;
  border-radius: var(--buttonBorderRadius);
  font-size: 14px;
  color: #fff;
  border: 0;

  &:hover {
    text-decoration: none;
    background: #364f61;
  }
}
