@import '../../styles/customMediaQueries.css';

.testimonialWrapper {
  margin: 0 2%;
}

.testimonial {
  height: 410px;
  background-color: var(--matterColorNotSoBright);
  border-radius: 2em;
  padding: 20px;
  margin: 5px;
  color: var(--matterColor);
  @media (--viewportTiny) {
    max-height: 330px;
  }
  @media (min-width: 460px) {
    max-height: 280px;
  }
  @media (--viewportSmall) {
    max-height: 250px;
  }
  @media (--viewportMedium) {
    padding: 30px;
  }
  @media (--viewportLarge) {
    max-height: 220px;
  }
}

.reviewStar {
  width: 15px;
  height: 15px;
  margin-right: 2px;
  margin-bottom: 15px;
}

.testimonialGiver {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.testimonialPhoto {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 20px;
}
.testimonialName {
  composes: h3 from global;
  font-weight: var(--fontWeightExtraBold);
  margin-top: 0;
  margin-bottom: 0;
}
.testimonialLocationAndDate {
  composes: marketplaceTinyFontStyles from global;
  margin-top: 0;
  margin-bottom: 0;
}
