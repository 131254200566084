@import '../../styles/customMediaQueries.css';

.heading {
  composes: h1 from global;
  margin-top: 0px;
  text-align: center;
}

.contactUsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.contactUsContent {
  width: 33%;
  min-width: 300px;
  padding: 10px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 115px;

  @media (--viewportMedium) {
    padding: 50px 0 0 0;
  }
}

.contactImage {
  width: 75px;
  height: 45px;
  padding: 0 15px;
}

.subtitle {
  text-decoration: none;
  color: var(--matterColor);
}

.subtitle:hover {
  text-decoration: none;
}

.contactUsCallBack {
  cursor: pointer;
}

.contactUsCallBack:hover {
  text-decoration: underline;
  color: var(--marketplaceColor);
}

.contactUsSuccess {
  composes: h5 from global;

  margin: 0px;
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);
  padding: 15px;
  border-radius: 5px;

  @media (--viewportMedium) {
    padding: 15px 20px;
  }
}
