@import '../../styles/customMediaQueries.css';

.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.messageLabel {
  margin: 10px 0 5px 0;

  @media (--viewportMedium) {
    margin: 15px 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  margin: 24px 0 0 0;
  border-bottom-color: var(--matterColorAnti);
}
.messageInput {
  margin: 5px 0;
  padding: 0;
}

.declineMessage {
  margin: 24px 0 0 0;
  border-bottom-color: var(--matterColorAnti);
}
.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin: 48px 0 24px 0;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
.checkboxMessage {
  composes: marketplaceBodyFontStyles from global;
}
