.root {
  padding: 0 12px;
  border-radius: 30px;
  color: var(--marketplaceColorDark);
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  top: 8px;
  right: 8px;
  border: 2px solid;
}
