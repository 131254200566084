@import '../../styles/customMediaQueries.css';

.heading {
  composes: h2 from global;
  margin-top: 0;
}

.qrCodeWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 9px 0;
}

.qrCode {
  width: 70px;
  margin-right: 20px;
}

.qrCodeColumn {
  margin-top: 0px;
  width: calc(100% - 90px);
}

.downloadButton {
  max-width: fit-content;
  padding: 0 30px;
}

.downloadList > li {
  margin: 0;
  padding: 0 0 20px 9px;
}

.downloadList {
  margin: 20px;
  padding: 0;
}

.image {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  margin-top: 9px;

  @media (--viewportMedium) {
    width: 35%;
  }
}
