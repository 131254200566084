@import '../../styles/customMediaQueries.css';

div.root :global(.Collapsible__trigger) {
  display: block;
  position: relative;
  padding: 2px 50px 2px 0;
  border-bottom: none;
}

div.root :global(.Collapsible__trigger.is-open) h3 {
  /* font-weight: bold; */
  color: var(--matterColorDark);
  &:hover {
    color: var(--matterColor);
  }
}

div.root :global(.Collapsible__trigger) h3 {
  margin-bottom: 0;
  font-weight: 500;
  cursor: pointer;

  color: var(--matterColor);
  &:hover {
    color: var(--matterColorDark);
  }
}

div.root :global(.Collapsible__trigger::after) {
  content: '▼';
  position: absolute;
  right: 6px;
  top: 13px;
  display: block;
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 24px;
  transition: transform 300ms;
  text-align: center;
  background-color: transparent;
  color: var(--marketplaceColor);
  border-radius: 50%;
  /* padding: 0 8px 16px 8px; */
  padding: 8px;
  cursor: pointer;
}

div.root :global(.Collapsible__trigger.is-open::after) {
  transform: rotateZ(180deg);
}

div.root :global(.Collapsible__trigger.is-disabled) {
  opacity: 0.5;
  background-color: grey;
}

ul.faqAnswerDotPoints {
  list-style: circle inside;
}
