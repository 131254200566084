.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.currencyInputWrapper {
  display: flex;
  align-items: center;
}

.currencySymbol {
  margin-right: 8px;
  font-size: 1em;
}

input[type='number'] {
  flex: 1;
}
