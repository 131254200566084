@import '../../styles/customMediaQueries.css';

.root {
}

.content {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  background-color: var(--matterColorLight);
  border-radius: 1.5em;
  border: 1px solid var(--matterColorNegative);
  margin: 20px 0;

  @media (--viewportMedium) {
    width: 94vw;
  }

  @media (--viewportLarge) {
    width: 100%;
  }
}

.avatar {
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-right: 20px;
  }
}

.heading {
  composes: h2 from global;
  margin-top: 0;
  margin-bottom: 8px;
}

.link {
  white-space: nowrap;
}

.mobileBio,
.desktopBio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  transition: all 1s ease-in;
  composes: h5 from global;

  padding-top: 10px;
}

.mobileBio {
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopBio {
  display: none;
  margin: 18px 0 0 0;

  @media (--viewportMedium) {
    display: block;
  }
}

.showMore {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  margin: 0 0 0 5px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    line-height: 16px;
  }
}

.links {
  composes: h5 from global;
  margin-top: 13px;

  @media (--viewportMedium) {
    margin: 16px 0 0 0;
  }
}

.withBioMissingAbove {
  @media (--viewportMedium) {
    /* Fix baseline alignment if bio is missing from above */
    margin-top: 16px;
  }
}

.linkSeparator {
  margin: 0 10px;
  color: var(--marketplaceColor);
}

.headingRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;

  @media (--viewportMedium) {
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
}

.editProfileContainer {
  display: flex;
  justify-content: space-between;

  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: baseline;
  }
}

.editProfileDesktop {
  composes: h5 from global;
  display: none;
  margin-bottom: 0;
  white-space: nowrap;

  @media (--viewportMedium) {
    display: inline;
  }
}

.editProfileMobile {
  composes: h5 from global;
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}

.contact {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 14px;
    line-height: 16px;
  }
}

.subheading {
  composes: h5 from global;
  color: var(--matterColorAntiMid);
  margin-top: 4px;
  margin-bottom: 0;
}
