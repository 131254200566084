@import '../../styles/customMediaQueries.css';

.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.message {
  margin: 24px 0 0 0;
  border-bottom-color: var(--matterColorAnti);
}

.messageInput {
  margin: 5px 0;
  padding: 0;
}

.cancelMessage {
  margin: 24px 0 0 0;
  border-bottom-color: var(--matterColorAnti);
}

.cancelTermsAccept {
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.submitButton {
  margin-top: auto;
  margin: 48px 0 24px 0;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    margin-top: 100px;
  }
}
.checkboxMessage {
  composes: marketplaceBodyFontStyles from global;
}
