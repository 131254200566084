@import '../../styles/customMediaQueries.css';

.downloadButton {
  composes: button buttonText buttonBordersSecondary buttonColorsSecondary from global;

  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 39px;
  letter-spacing: 0;

  font-weight: var(--fontWeightMedium);

  padding: 0 16px;
  min-height: auto;
  min-width: 150px;
  height: 41px;

  display: inline-block;
  margin: 17px 0;
  width: auto;
}
