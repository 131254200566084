@import '../../styles/customMediaQueries.css';

.root {
  /* position: relative; */
}

.fieldGroup {
  border: 2px solid var(--matterColorNegative);
  background-color: var(--matterColorBright);
  margin: 24px 0;
  padding: 3px 7px 14px 7px;

  @media (--viewportMedium) {
    margin: 32px 0;
    padding: 5px 10px 17px 10px;
  }
}

.driversLicenceNumber {
  margin-top: 12px;
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.driversLicenceLabel,
.driversLicenceIssuedBy {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}
