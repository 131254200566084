@import '../../styles/customMediaQueries.css';

.root {
}

.customerStats {
  /* h5 marketplace default */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin-bottom: 8px;

  margin-top: 13px;

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 16px 0 0 0;
  }
}

.linkSeparator {
  margin: 0 10px;
}

.customerStats .linkSeparator {
  color: black;
}

.customerStats span {
  white-space: nowrap;
}

.star {
  fill: black;
  height: 12px;
  vertical-align: unset;
  margin-left: -5px;
}

.customerStatLong {
  margin: 10px 0 0 0;
}

.customerStatLong > p {
  /* h5 marketplace default */
  margin: 0;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 10px;
  }
}

@keyframes loadingSpinnerFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loadingSpinner {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  opacity: 0;

  /* animation shorthand property doesn't work with local scope of CSS Modules */
  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.errorContainer {
  display: block;
  flex: 1;
  padding: 26px 24px 0 0;
}

.error {
  composes: h5 from global;

  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}
