@import '../../styles/customMediaQueries.css';

.root {
  composes: h4 from global;
}

.subheading {
  composes: h1 from global;
  margin-top: 0px;
}

.benefitHeading {
  font-weight: bolder;
}

.benefitsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  justify-content: space-between;
  align-items: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.benefit {
  align-items: flex-start;
  margin: 12px 0;
  @media (--viewportMedium) {
    width: 25%;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
  }

  display: flex;
}

.benefitFirst {
  align-items: flex-start;
  margin: 12px 0;
  @media (--viewportMedium) {
    width: 25%;
    padding-left: 0px;
    padding-right: 20px;
    flex-direction: column;
  }

  display: flex;
}
.benefitLast {
  align-items: flex-start;
  margin: 12px 0;
  @media (--viewportMedium) {
    width: 25%;
    padding-left: 20px;
    padding-right: 0px;
    flex-direction: column;
  }

  display: flex;
}

.icon {
  width: 60px;
  margin-right: 20px;
  margin-top: 20px;

  @media (--viewportMedium) {
    width: 80px;
    margin: 0;
  }
}

.link {
  padding-left: 5px;
}
