@import '../../styles/customMediaQueries.css';

.root {
  padding: 0;
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}

.trailerValueWrapper {
  margin-bottom: 20px;
  gap: 20px;
  margin-bottom: 30px;
  padding: 0;
}

.textWrapper {
  margin-bottom: 20px;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.boxWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 24px;
}

.listingTypeBox {
  padding: 20px;
  background-color: var(--matterColorBright);
  border: solid 1px var(--matterColorNegative);
  border-radius: 8px;
  cursor: pointer;
  width: calc(50% - 20px);
  box-sizing: border-box;
  transition: all 0.3s ease;
  box-shadow: var(--boxShadow);
}

.listingTypeBox:hover {
  border-color: var(--marketplaceColor);
  transform: translateY(-5px);
}

.listingTypeBox.selected {
  border-color: var(--marketplaceColor);
  background-color: var(--marketplaceColorLight);
  box-shadow: var(--bosShadowPopup);
}

.listingTypeBox h2 {
  font-size: 18px;
  font-weight: 600;
  color: var(--matterColorDark);
}

.listingTypeBox ul {
  list-style-type: none;
  padding: 0;
  margin: 15px 0 0 0;
}

.listingTypeBox ul li {
  font-size: 14px;
  color: var(--matterColor);
  margin-bottom: 5px;
}

.listingTypeBox ul li::before {
  content: '✅';
  margin-right: 8px;
}
.inputWrapper {
  display: flex;
  align-items: center;
}

.dollarSign {
  margin-right: 8px;
  font-size: 16px;
  color: var(--matterColorDark);
}

.input {
  padding: 8px;
  margin-top: 5px;
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  box-sizing: border-box;
}

.submitTrailerValueButton {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;

  letter-spacing: 0.2px;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: 100%;
  height: auto;
  min-height: 0;

  &:hover,
  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.listingTypeButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;

  padding: 10px 20px;
  background-color: var(--marketplaceColor);
  border: none;
  font-size: 14px;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s ease;
}

.listingTypeButton:hover {
  background-color: var(--marketplaceColorDark);
  color: white;
}

.infoText {
  font-size: 12px;
  color: var(--matterColor);
  margin-top: 10px;
}

@media (max-width: 768px) {
  .boxWrapper {
    flex-direction: column;
    gap: 20px;
  }

  .listingTypeBox {
    width: 100%;
    margin-bottom: 15px;
  }
}
