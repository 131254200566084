@import '../../styles/customMediaQueries.css';

.root {
}

.infoBoxInformation {
  composes: h5 from global;

  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);
  padding: 15px;
  border-radius: 5px;

  @media (--viewportMedium) {
    margin: 10px 0;
    padding: 15px 20px;
  }
}

.infoBoxSuccess {
  composes: h5 from global;

  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);
  padding: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  text-align: left;

  @media (--viewportMedium) {
    margin: 10px 0;
    padding: 15px 20px;
  }
}

.icon {
  margin-right: 6px;
  min-width: 30px;
}

.infoBoxWarning {
  composes: marketplaceModalPasswordMargins from global;
  background: var(--failColorLight);
  border: 1px solid var(--failColor);
  border-radius: 5px;
  padding: 3px 15px;
}
