@import '../../styles/customMediaQueries.css';

.root {
}

.driversLicenceLabel {
  display: block;

  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.licenceValue {
  margin-top: 0;
}

.bottomWrapper {
  margin-top: 46px;

  @media (--viewportMedium) {
    margin-top: 96px;
  }
}

.error {
  composes: h4 from global;
  color: var(--failColor);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}
