@import '../../styles/customMediaQueries.css';

.dayOfWeek {
  vertical-align: top;
  padding-right: 10px;
}
.root {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.sectionOpeningHours {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.openingHoursTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}
