.root {
}

.bookingInfo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.nowrapDate {
  @media (min-width: 320px) {
    white-space: nowrap;
  }
}

.nowrapTime {
  @media (min-width: 320px) {
    white-space: nowrap;
  }
}
