@import '../../styles/customMediaQueries.css';

.root {
  & p,
  & li {
    /* default h4 marketplace font */
    font-weight: var(--fontWeightMedium);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      margin-top: 17px;
      margin-bottom: 15px;
    }
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 23px 0 19px 0;

    @media (--viewportMedium) {
      margin: 24px 0 24px 0;
    }
  }

  & .lastUpdated {
    /* default marketplaceBodyFontStyles marketplace font */
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    margin-top: 0;
    margin-bottom: 55px;

    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 32px;
      margin-top: 0;
      margin-bottom: 54px;
    }
  }

  & ul {
    padding-left: 30px;
    list-style: disc outside none;
  }

  & .subpoint {
    margin-left: 20px;
  }
  & .subsubpoint {
    margin-left: 40px;
  }
  & .subsubsubpoint {
    margin-left: 60px;
  }
}
