@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceBodyFontStyles from global;

  margin: 30px;
}

.heading {
  margin-bottom: 11px;
}

.section {
  display: flex;
  flex-direction: column;
}

.submitButton {
  margin-top: 96px;
  flex-shrink: 0;
  /* Mobile phones introduce bottom-bar, for which we need to give 96px vertical space */
  padding-bottom: 96px;

  @media (--viewportMedium) {
    padding-bottom: 0;
  }
}

.fieldSelect {
  composes: marketplaceTabNavHorizontalFontStyles from global;

  background: none;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 50%;
}

select.fieldSelect {
  padding-right: 0;
}

.error {
  color: var(--failColor);
}

.formWrapper {
  composes: marketplaceTabNavHorizontalFontStyles from global;

  /* Layout */
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding-top: 4px;
  padding-bottom: 19px;
  border-top: solid 1px var(--matterColorNegative);
  border-bottom: solid 1px var(--matterColorNegative);
}

.fieldWrapper {
  composes: marketplaceTabNavHorizontalFontStyles from global;

  display: flex;
  justify-content: space-around;
  align-items: center;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: nowrap;
  padding-bottom: 6px;
}

.requirement {
  width: 50%;
  padding-top: 8px;
}
