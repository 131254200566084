@import '../../styles/customMediaQueries.css';

.heading {
  composes: h2 from global;
  margin-top: 0;
}
.subheading {
  composes: h3 from global;
  font-weight: bolder;
  margin-bottom: 0;
}

.boxTrailersContent {
  padding: 1rem;

  &:last-of-type {
    margin-bottom: 48px;
  }
}

.list {
  padding-left: 2rem;
  list-style-type: disc;
}
