@import '../../styles/customMediaQueries.css';

.stepNumHeading {
  font-weight: bolder;
  margin-right: 15px;
  padding: 0;
}

.stepsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  justify-content: space-between;
  align-items: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.icon {
  width: 60px;
  margin-right: 20px;
  margin-top: 20px;

  @media (--viewportMedium) {
    width: 80px;
    margin: 0;
  }
}

.stepWords {
  composes: h4 from global;
}

.step {
  align-items: flex-start;
  margin: 12px 0;
  @media (--viewportMedium) {
    width: 33%;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
  }

  display: flex;
}

.stepHeadingAndWords {
  display: flex;
  flex-direction: row;
}
