.root {
  position: relative;
  margin: 0 10px;
  width: 220px;
  height: 24px;
}

.activeHandle {
  z-index: 1;
}
