@import '../../styles/customMediaQueries.css';

.root {
}

.ownerStats {
  border: 1px solid var(--matterColorNegative);
  background-color: var(--matterColorNotSoBright);
  border-radius: 5px;
  padding: 12px;

  /* h5 marketplace default */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin-bottom: 8px;

  margin-top: 13px;

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 24px 0 0 0;
  }
}

.linkSeparator {
  margin: 0 10px;
}

.ownerStats span {
  white-space: nowrap;
}

.star {
  fill: black;
  height: 12px;
  vertical-align: unset;
  margin-left: -5px;
}

.ownerStatLong {
  margin: 10px 0 0 0;
}

.ownerStatLong > p {
  /* h5 marketplace default */
  margin: 0;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 10px;
  }
}

.superOwnerIcon {
  height: 40px;
  margin: 0px 2px 3px -8px;

  @media (--viewportMedium) {
  }
}

.superOwnerWrapper {
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid var(--matterColorNegative);
  display: flex;
}
.superOwnerTitle {
  /* h5 marketplace default */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin-bottom: 8px;
  margin-top: 8px;

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.superOwnerExplanation {
  composes: marketplaceEvenTinierFontStyles from global;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 5px;
}
