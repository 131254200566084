@import '../../styles/customMediaQueries.css';

.root {
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.prefix {
  color: var(--matterColorAnti);
}

.wrapper {
  display: flex;
  align-items: center;
}
