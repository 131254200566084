.tooltipWrapper {
  width: calc(100% - 48px);
  margin: 0 24px;

  @media (--viewportMedium) {
    width: 100%;
    margin: 0;
  }
}

.tooltipContent {
  width: 100%;
  composes: h5 from global;
  padding: 12px;
  background-color: var(--marketplaceColorLight);
  color: var(--matterColor);
  border-radius: 10px;
  justify-content: center;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
}

.tooltipContentWarning {
  width: 100%;
  composes: h5 from global;
  padding: 12px;
  background-color: var(--marketplaceColorLight);
  border: 3px solid var(--failColor);
  color: var(--matterColor);
  border-radius: 10px;
  justify-content: center;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
}

.arrowDown {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  margin-top: 0;
  margin-left: calc(50% - 10px);
  border-top: 10px solid var(--marketplaceColorLight);
}

.arrowUp {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  margin-bottom: 0;
  margin-top: 5px;
  margin-left: calc(50% - 10px);
  border-bottom: 10px solid var(--marketplaceColorLight);
}
