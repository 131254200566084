@import '../../styles/customMediaQueries.css';

.root {
  composes: h4 from global;
}

.intro {
  composes: h4 from global;
}

.icon {
  width: 60px;
  margin-right: 20px;
  margin-top: 20px;

  @media (--viewportMedium) {
    width: 80px;
    margin: 0;
  }
}

.featureHeading {
  font-weight: bolder;
}

.featuresContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  justify-content: space-between;
  align-items: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.feature {
  composes: h4 from global;

  align-items: flex-start;
  @media (--viewportMedium) {
    width: 33%;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
  }

  display: flex;
}
