@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.errorTitle {
  composes: h3 from global;

  font-weight: var(--fontWeightBold);
}

.submitButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.helperTextTitle {
  font-weight: var(--fontWeightSemiBold);
}

.resendEmailButton {
  margin-bottom: 20px;
}
