@import '../../styles/customMediaQueries.css';

.root {
  composes: defaultBackgroundImage from global;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  min-height: 350px;

  /* Text positioning is done with paddings */
}

.heroContent {
  margin: 0 24px 0 24px;
}

.heroMainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    width: var(--SectionHero_desktopTitleMediumWidth);
  }

  @media (--viewportLarge) {
    width: var(--SectionHero_desktopTitleLargeWidth);
  }
}

.heroMainTitleFEDelay {
}

.heroSubTitle {
  composes: h5 from global;
  color: var(--matterColorLight);
  margin: 1em 0 3em 0;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMediumWidth);
  }

  @media (--viewportLarge) {
    max-width: var(--SectionHero_desktopTitleLargeWidth);
  }
}
.heroSubTitleFEDelay {
}

.searchForm {
  text-align: left;

  @media (--viewportMedium) {
    display: block;
    max-width: var(--SectionHero_desktopTitleMediumWidth);
  }

  @media (--viewportLarge) {
    max-width: var(--SectionHero_desktopTitleLargeWidth);
  }
}

.trending {
  composes: h5 from global;
  margin-top: -2.5em;
  color: var(--matterColorLight);
  line-height: 16px;
  font-size: 0.8rem;
  z-index: 1;
  position: relative;
}

.trending a {
  color: var(--matterColorLight);
}

.heroButtonFEDelay {
}
