@import '../../styles/customMediaQueries.css';

.subheading {
  composes: h1 from global;
  margin-top: 0px;
}

.learnMoreLink {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  margin-top: 20px;
  @media (--viewportMedium) {
    width: 260px;
  }
}

.learnMoreButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
