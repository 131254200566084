@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.liabilityButtons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.liabilityButton {
  padding: 5px 20px;
  background-color: var(--matterColorBright);
  border: solid 1px var(--matterColorNegative);
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
}

.liabilityButton:hover {
  background-color: var(--matterColorBright);
  border: solid 1px var(--matterColorDark);
}

.liabilityButton:focus {
  outline: none;
  border-color: var(--marketplaceColor);
}

.selected {
  background-color: var(--marketplaceColorLight);
  color: var(--matterColor);
  border-color: var(--marketplaceColor);
}

.selected:hover {
  background-color: var(--marketplaceColor);
}
