@import '../../styles/customMediaQueries.css';

.root {
  background-image: url('../../assets/mustering-hero.png');
  background-size: cover;
  background-position: top center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 800px;

  @media (--viewportMedium) {
    background-position: top center;
    height: 100vh;
  }
}

.heroContent {
  margin: 0 24px 0 24px;
  display: flex;

  @media (--viewportMedium) {
    margin: 2vh 5vw 0 5vw;
  }

  @media (--viewportLarge) {
    margin: 2vh 5vw 0 5vw;
  }

  @media (--viewportLargeWithPadding) {
    margin: 3vh 7vw 0 7vw;
  }

  @media (--viewportXLarge) {
    margin: 3vh 12vw 0 12vw;
  }
}

.heroMainTitleDiv {
  width: 100%;
  text-align: center;
  position: relative;
  top: -150px;

  @media (--viewportMedium) {
    width: 50%;
    text-align: left;
    top: -50px;
  }
}

.heroMainTitle {
  font-weight: var(--fontWeightBold);
  font-size: 26px;
  line-height: 26px;
  letter-spacing: -1px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 25px;
  margin-bottom: 20px;

  /* color: var(--matterColorLight); */

  @media (--viewportMedium) {
    composes: marketplaceSmallerHeroTitleFontStyles from global;
  }
}

.heroSubTitle {
  font-size: 0.85em;
  line-height: 0.95em;

  @media (--viewportMedium) {
    font-size: 1.3em;
    line-height: 1.25em;
    max-width: 370px;
  }
}

.widgetContainer {
  max-width: 450px;
}
