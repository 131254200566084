@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBaseStyles from global;
  padding: 0;
  padding-top: 40px;

  @media (--viewportMedium) {
    padding: var(--modalPaddingMedium);
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
/* .modalIcon {
  @apply --marketplaceModalIconStyles;
} */

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
  padding: 0 24px;
  color: var(--matterColor);

  @media (--viewportMedium) {
    padding: 0;
  }
}

/* .reviewee {
  white-space: nowrap;
} */

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
  margin-bottom: 20px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.message {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.reasonTitle {
  font-weight: 600;
}
