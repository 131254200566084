@import '../../styles/customMediaQueries.css';

.heading {
  composes: h2 from global;
  margin-top: 0;
}

.listingsTable {
  width: 100%;
  border-collapse: collapse;
}

.listingsTable td {
  border: 1px solid var(--matterColorNegative);
  padding: 5px;
}

.listingsTable tr:nth-child(odd) {
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorNotSoBright);
  }
}

.listingsTableColumn {
  min-width: 78px;
  text-align: center;

  @media (--viewportMedium) {
    min-width: 90px;
  }
}

.buttonLink {
  composes: button buttonText buttonBorders buttonColors from global;

  min-height: 30px;
  margin: 0;
  padding: 5px 8px;

  @media (--viewportMedium) {
    min-height: 30px;
    margin: 0;
    padding: 5px 10px;
  }
}
