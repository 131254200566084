@import '../../styles/customMediaQueries.css';

.root {
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.hint {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  margin-top: 5px;
  margin-bottom: 0;
  padding-bottom: 2px;
}
