@import '../../styles/customMediaQueries.css';

.root {
}

.content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.videoContainer {
  width: 100%;
  padding-bottom: 40px;
  @media (--viewportMedium) {
    width: 60%;
    padding-right: 40px;
  }
}

.rightContainer {
  composes: h4 from global;
  width: 100%;
  @media (--viewportMedium) {
    width: 40%;
  }
}

.intro {
  composes: h4 from global;
}

.subheading {
  composes: h1 from global;
  margin-top: 0px;
}

.icon {
  width: 60px;
  margin-right: 20px;
  margin-top: 20px;

  @media (--viewportMedium) {
    width: 80px;
    margin: 0;
  }
}

.featureHeading {
  font-weight: bolder;
  margin-top: 0;
}

.featureHeadingAndWords {
  margin-bottom: 1.5rem;
}
