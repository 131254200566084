@import '../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;
  margin-top: 0;
}

.steps {
  display: flex;
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
  margin-bottom: 5px;
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;
  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.step :global(.Collapsible__trigger) {
  display: block;
  position: relative;
}

.step :global(.Collapsible__trigger::after) {
  content: '▼';
  position: absolute;
  right: 6px;
  top: -2px;
  display: block;
  width: 40px;
  height: 40px;
  transition: transform 300ms;
  text-align: center;
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  border-radius: 50%;
  padding: 8px;
  @media (--viewportMedium) {
    content: '';
    display: none;
  }
}

.step :global(.Collapsible__trigger.is-open::after) {
  transform: rotateZ(180deg);
}

.step :global(.Collapsible__trigger.is-disabled) {
  opacity: 0.5;
  background-color: grey;
}
