@import '../../styles/customMediaQueries.css';

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
  justify-content: center;
  align-items: center;
}

.step {
  margin-bottom: 60px;
  text-align: center;
  @media (--viewportMedium) {
    width: 506px;
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.step:last-of-type {
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  composes: h1 from global;

  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.image {
  max-width: 70%;
  max-height: 243px;
  @media (--viewportMedium) {
    max-width: 90%;
    max-height: 313px;
  }
}

.link {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  max-width: 220px;
  display: inline-block;
}

.wideLink {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  max-width: 240px;
  display: inline-block;
}
