@import '../../styles/customMediaQueries.css';
/*
If you need to add css to the Logo you can do it here.
*/
.logoMobile {
  display: block;
  height: 20px;

  @media (--viewportTiny) {
    width: 200px;
    height: 27px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.logoDesktop {
  display: none;
  width: auto;
  height: 27px;

  @media (--viewportLarge) {
    display: block;
  }
}
