@import '../../styles/customMediaQueries.css';

.root {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.submitButton {
  margin-top: 24px;
  min-height: auto;
  min-width: 150px;
  height: 41px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  padding: 0 16px;

  display: inline-block;
  margin: 17px 0 60px 0;
  width: auto;

  @media (--viewportMedium) {
    margin: 17px 0 0 0;
  }
}
