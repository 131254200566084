@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--footerColor);
  /* Text positioning is done with paddings */
}

.heroContent {
  margin: 0 24px 0 24px;
  display: flex;
  flex-wrap: wrap-reverse;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 5vw;
  }

  @media (--viewportLarge) {
    margin: 0 11vw 0 11vw;
  }

  @media (--viewportLargeWithPadding) {
    margin: 0 14vw 0 14vw;
  }

  @media (--viewportXLarge) {
    margin: 0 24vw 0 24vw;
  }

  /* Special viewport for adjusting the heroContent's placement */
  /*
  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1352px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  } */
}

.heroMainTitleDiv {
  width: 100%;
  text-align: center;

  @media (--viewportMedium) {
    width: 45%;
    text-align: left;
  }
}

.imageDiv {
  width: 100%;
  text-align: center;
  padding-top: 20px;

  @media (--viewportMedium) {
    width: 55%;
    text-align: right;
    padding-top: 60px;
  }

  @media (--viewportLarge) {
    padding-top: 30px;
  }

  min-width: 200px;
  z-index: 2;
}

.heroMainTitle {
  composes: marketplaceSmallerHeroTitleFontStyles from global;
  color: var(--matterColorLight);
}

.heroButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;

  margin-top: 30px;

  @media (--viewportMedium) {
    width: 260px;
  }

  border-radius: 5em;
}

.image {
  width: 80%;

  @media (--viewportMedium) {
    width: auto;
    height: 250px;
  }
  @media (--viewportLarge) {
    height: 350px;
  }
}
