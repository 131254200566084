.customerNoteContainer {
  position: relative;
}

.close {
  composes: marketplaceModalCloseStyles from global;
  position: absolute;
  margin-top: 20px;
}

.closeText {
  composes: marketplaceModalCloseText from global;
  font-size: 10px;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

.link {
  white-space: nowrap;
}

.links {
  composes: h5 from global;
  margin-top: 13px;

  @media (--viewportMedium) {
    margin: 16px 0 0 0;
  }
}

.errorContainer {
  display: block;
  flex: 1;
  padding: 26px 24px 0 0;
}

.error {
  composes: h5 from global;

  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}
