@import '../../styles/customMediaQueries.css';

.content {
  margin: 10px;
}

.title {
  composes: h1 from global;

  margin-top: 0;
  margin-bottom: 30px;
}

.locations {
  display: flex;
  flex-direction: row;
  margin-top: 32px;

  @media (--viewportMedium) {
    margin-top: 57px;
  }
}

.location {
  width: 100%;
  margin-top: 0;
  margin-right: 10px;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin: 5px;
    width: 226px;
  }

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);
  max-height: 300px;

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */
}

.allLinksWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
  padding: 0 5px;
  filter: brightness(60%);
}

.bacchusMarshImage {
  object-position: 0 50%;
}

.frankstonImage {
  object-position: 0 60%;
}

.malvernEastImage {
  object-position: 0 25%;
}

.melbourneImage {
  object-position: 0 40%;
}

.mitchamImage {
  object-position: 0 50%;
}

.brisbaneImage {
  object-position: 0 40%;
}

.newcastleImage {
  object-position: 0 35%;
}

.linkText {
  text-align: center;
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorLight);
  margin-top: 15px;
  margin-bottom: 0;
  position: relative;
  top: -10px;

  @media (--viewportTiny) {
    font-size: 1.2em;
    top: -20px;
  }

  @media (--viewportSmall) {
    font-size: 1.4em;
    top: -40px;
  }

  @media (--viewportMedium) {
    font-size: 1em;
    top: -15px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}

:global(ul .slick-dots) {
  margin-top: 100px;
}

:global(ul .slick-dots li) {
  margin: 0;
  margin-top: 0px;
  width: 15px;
}

:global(ul .slick-dots li button:before) {
  font-size: 0.75rem;
}
