@import '../../styles/customMediaQueries.css';

.hireImage {
  width: 70px;
  height: 70px;

  @media (--viewportSmall) {
    width: 125px;
    height: 125px;
  }
  @media (--viewportMedium) {
    width: 250px;
    height: 230px;
  }
  @media (--viewportLarge) {
    width: 300px;
    height: 290px;
  }
}

.hireImageRectangular {
  width: 80px;
  height: 60px;

  @media (--viewportSmall) {
    width: 135px;
    height: 100px;
    margin-top: 15px;
  }
  @media (--viewportMedium) {
    width: 270px;
    height: 200px;
  }
  @media (--viewportLarge) {
    width: 340px;
    height: 240px;
  }
}

.heading {
  composes: h6 from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightExtraBold);
  margin-bottom: 0;
  font-size: 0.75rem;
}

.stepNumHeading {
  composes: h1 from global;
  margin-top: 0px;
}

.stepsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.stepImage {
  width: 30%;
  text-align: center;
  padding-top: 30px;

  @media (--viewportMedium) {
    width: 50%;
    padding: 40px 80px;
    height: 320px;
  }
}

.stepRectangleImage {
  width: 30%;
  text-align: center;
  padding-top: 30px;

  @media (--viewportMedium) {
    width: 50%;
    padding: 40px 80px;
    height: 320px;
  }
}

.stepWords {
  /* composes: h3 from global; */
  width: 70%;
  padding-bottom: 40px;
  @media (--viewportMedium) {
    width: 50%;
    padding: 50px;
  }
}

.box1 {
  order: 1;

  @media (--viewportMedium) {
    text-align: right;
  }
}
.box2 {
  order: 2;
  padding-bottom: 40px;
}
.box3 {
  order: 3;
  @media (--viewportMedium) {
    order: 4;
    text-align: left;
  }
}
.box4 {
  order: 4;
  @media (--viewportMedium) {
    order: 3;
    padding-bottom: 0;
  }
}
.box5 {
  order: 5;
  @media (--viewportMedium) {
    text-align: right;
  }
}
.box6 {
  order: 6;
  padding-bottom: 0;
}

.signupLink {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  margin-top: 20px;
  max-width: 260px;
}

.createListingLink {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  margin-top: 20px;
  max-width: 260px;
}
