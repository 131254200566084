@import '../../styles/customMediaQueries.css';

.discountCodeContainer {
  display: flex;
}

.textareaDiscountCode {
  width: 210px;
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.applyDiscountButton {
  margin-left: 20px;
}

.errorMessage {
  color: var(--failColor);
}
