@import '../../styles/customMediaQueries.css';

.heading {
  composes: h1 from global;
  margin-top: 0px;
  text-align: center;
}

.content {
  width: 90%;
  margin: 0 5%;
}

.testimonialWrapper {
  margin: 0 2%;
}

.testimonial {
  width: 96%;
  height: 400px;
  overflow: scroll;
  background-color: var(--matterColorLight);
  border-radius: 2em;
  padding: 20px;
  @media (--viewportMedium) {
    padding: 40px;
  }
}

.reviewStar {
  width: 15px;
  height: 15px;
  margin-right: 2px;
  margin-bottom: 15px;
}

.testimonialGiver {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.testimonialPhoto {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 20px;
}
.testimonialName {
  composes: h3 from global;
  font-weight: var(--fontWeightExtraBold);
  margin-top: 0;
  margin-bottom: 0;
}
.testimonialLocationAndDate {
  composes: marketplaceTinyFontStyles from global;
  margin-top: 0;
  margin-bottom: 0;
}
