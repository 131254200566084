@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name,
.phoneNumber,
.reasonForCallBack,
.otherInfo,
.email {
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.submitButton {
  margin-top: 24px;
}

.error {
  composes: h5 from global;
  color: var(--failColor);
}
