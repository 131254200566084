@import '../../styles/customMediaQueries.css';

.content {
  margin: 10px;
}

:global(div .slick-next::before) {
  color: var(--matterColorDark);
}
:global(div .slick-prev::before) {
  color: var(--matterColorDark);
}
