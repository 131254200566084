@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.error {
  color: var(--failColor);
}

.submitButton {
  flex-shrink: 0;
  margin-top: auto;
  margin: 24px 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 60px;
  }
}

.orientationTitle {
  composes: h5 from global;
  font-weight: bold;
  margin-top: 25px;
}

.imageOrientationWrapper {
  composes: marketplaceSmallFontStyles from global;
  background-color: var(--matterColorNegative);
  padding: 8px 12px;
  border-radius: 1em;
  margin-bottom: 25px;
  margin-top: 0;
}

.tip {
  margin: 0;
  line-height: normal;
}

.uploadOrientationImages {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 12px 0;
  justify-content: space-evenly;
  align-items: flex-start;
  text-align: center;
}

.orientedImage {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(50% - 24px);
}

.trailerImagePortrait {
  max-width: 300px;
  border: 2px solid var(--failColor);
  width: 55%;

  @media (--viewportMedium) {
    width: 27.2%;
  }
}

.trailerImageLandscape {
  max-height: 300px;
  border: 2px solid var(--successColor);
  width: 90%;
  margin-top: 15px;

  @media (--viewportMedium) {
    width: 67%;
    margin-top: 0;
  }
}

.badOrientation {
  color: var(--failColor);
  fill: var(--failColor);
  stroke: var(--failColor);
  stroke-width: 1px;
  width: 40px;
  height: 40px;

  border-radius: 20%;
  border: 2px solid var(--failColor);
  background-color: var(--matterColorLight);
  padding: 6px;
  position: relative;
  top: -15px;

  @media (--viewportMedium) {
    top: -20px;
  }
}

.goodOrientation {
  color: var(--successColor);
  fill: var(--successColor);
  stroke: var(--successColor);
  stroke-width: 4px;
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 20%;
  border: 2px solid var(--successColor);
  background-color: var(--matterColorLight);
  padding: 6px;
  top: -15px;

  @media (--viewportMedium) {
    top: -20px;
  }
}
