@import '../../styles/customMediaQueries.css';

.root {
}

.dashboardSectionSubTitle {
  composes: h3 from global;
  color: var(--matterColorAnti);
}

.successIcon {
  margin-right: 5px;
}

.progressBar {
  width: 200px;
  height: 20px;
  background-color: var(--matterColorNegative);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.progressFill {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.5s ease-in-out;
  border-radius: 10px 0 0 10px;
}

.targetMarker {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: var(--matterColor);
  left: 90%;
}

.targetLabel {
  position: absolute;
  top: -2px;
  right: 5px;
  white-space: nowrap;
  font-size: 12px;

  @media (--viewportMedium) {
    top: -6px;
  }
}
