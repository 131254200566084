@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  box-shadow: none;
  margin-bottom: 19px;

  @media (--viewportLarge) {
    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}
