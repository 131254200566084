@import '../../styles/customMediaQueries.css';

.root {
  padding: 0;
}

.buttonWrapper {
  margin: 24px 0;
}

.discountRow {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.discountRow span {
  min-width: 120px;
}

.removeButton {
  max-width: 120px;
  flex-shrink: 0;
}

.addButton {
  max-width: 200px;
}

.trailerValueSection {
  margin: 24px 0;
  padding: 20px;
  background-color: var(--matterColorBright);
  border: 1px solid var(--matterColorNegative);
  border-radius: 8px;
}

.trailerValueRow {
  composes: marketplaceBodyFontStyles from global;

  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.trailerValue {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 24px;
  }
}

.changeValueButton {
  width: 250px;

  @media (--viewportMedium) {
    width: auto;
    margin-left: auto;
  }
}

.modalContainer {
  composes: marketplaceModalBaseStyles from global;
  padding: 24px;
}

.marketValueInput {
  margin: 24px 0;
}

.modalButtons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
}

.cancelButton {
  composes: buttonSecondary from global;
}

.instantBookSection {
  margin: 24px 0;
  padding: 24px;
  border: 1px solid var(--colorGreyLight);
  border-radius: 4px;
}

.instantBookSection h2 {
  margin: 0 0 16px 0;
  font-size: 20px;
  font-weight: var(--fontWeightSemiBold);
}

.instantBookSection p {
  margin: 0 0 16px 0;
  color: var(--colorGrey700);
}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.radioGroup > * {
  margin: 0;
}

.toggleWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.toggleLabel {
  font-size: 16px;
  color: var(--colorGrey700);
}

.modalContent {
  padding: 24px;
}

.modalContent h2 {
  margin: 0 0 16px 0;
  font-size: 20px;
  font-weight: var(--fontWeightSemiBold);
}

.modalContent p {
  margin: 0 0 16px 0;
  color: var(--colorGrey700);
}

.modalButtons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}
